<script>
import Layout from "@/router/layouts/main";
import { api } from "@/state/services";

export default {
  locales: {
    en: {
    },
    pt: {
    },
    es: {
    },
  },
  components: {
    Layout,
  },
  data() {
    return {

      ranking: {
        adesoes: {
          list: null,
        },
        boletospagos: {
          list: null,
        },
        inadimplencia: {
          list: null,
        },
        ticketmedio: {
          list: null,
        }
      }
    };
  },
  methods: {

    getAdesoes() {
      api
        .get('apvs/rankings/adesoes')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.adesoes.list = response.data.list;

            this.getStatData();
          }
        })
    },
    getBoletosPagos() {
      api
        .get('apvs/rankings/boletospagos')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.boletospagos.list = response.data.list;

            this.getStatData();
          }
        })
    },
    getInadimplencia() {
      api
        .get('apvs/rankings/inadimplencia')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.inadimplencia.list = response.data.list;

            this.getStatData();
          }
        })
    },
    getTicketMedio() {
      api
        .get('apvs/rankings/ticketmedio')
        .then(response => {
          if (response.data.status == 'success') {
            this.ranking.ticketmedio.list = response.data.list;

            this.getStatData();
          }
        })
    },
  },
  mounted() {
    this.getAdesoes()
    this.getBoletosPagos()
    this.getInadimplencia()
    this.getTicketMedio()
  },
};
</script>

<template>
  <Layout>
    <ol class="breadcrumb">
      <li class="breadcrumb-item">
        <router-link tag="a" to="/">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" style="fill: rgba(0, 0, 0, 1);">
            <path d="M5 22h14a2 2 0 0 0 2-2v-9a1 1 0 0 0-.29-.71l-8-8a1 1 0 0 0-1.41 0l-8 8A1 1 0 0 0 3 11v9a2 2 0 0 0 2 2zm5-2v-5h4v5zm-5-8.59 7-7 7 7V20h-3v-5a2 2 0 0 0-2-2h-4a2 2 0 0 0-2 2v5H5z">
            </path>
          </svg>
        </router-link>
      </li>
      <li class="breadcrumb-item">APVS</li>
      <li class="breadcrumb-item d-none d-sm-block">Rankings</li>
    </ol>

    <div class="page-title-box d-md-flex justify-content-between mb-3">
      <div>
        <h3>Rankings</h3>
        <p></p>
      </div>
    </div>

    <div class="row">
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Adesões <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div v-if="!ranking.adesoes.list" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <div class="d-flex flex-gap-10 justify-content-between align-items-center mb-2" v-for="(row, index) of ranking.adesoes.list" :key="index">
              <div class="d-flex flex-gap-10 align-items-center">
                <span class="ranking-bullet">{{ index + 1 }}</span>
                <span>{{ row.name }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Boletos Pagos <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div v-if="!ranking.boletospagos.list" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <div class="d-flex flex-gap-10 justify-content-between align-items-center mb-2" v-for="(row, index) of ranking.boletospagos.list" :key="index">
              <div class="d-flex flex-gap-10 align-items-center">
                <span class="ranking-bullet">{{ index + 1 }}</span>
                <span>{{ row.name }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Inadimplência – 1º Boleto <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div v-if="!ranking.inadimplencia.list" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <div class="d-flex flex-gap-10 justify-content-between align-items-center mb-2" v-for="(row, index) of ranking.inadimplencia.list" :key="index">
              <div class="d-flex flex-gap-10 align-items-center">
                <span class="ranking-bullet">{{ index + 1 }}</span>
                <span>{{ row.name }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
      <div class="col-md-6">
        <fieldset class="card-h-fix">
          <legend>Ticket Médio Top 50 <sup class="font-weight-light">{{ month_year }}</sup></legend>
          <div v-if="!ranking.ticketmedio.list" class="text-center">
            <b-spinner class="align-middle" variant="default" role="status"></b-spinner>
          </div>
          <div v-else>
            <div class="d-flex flex-gap-10 justify-content-between align-items-center mb-2" v-for="(row, index) of ranking.ticketmedio.list" :key="index">
              <div class="d-flex flex-gap-10 align-items-center">
                <span class="ranking-bullet">{{ index + 1 }}</span>
                <span>{{ row.name }}</span>
              </div>
            </div>
          </div>
        </fieldset>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.ranking-bullet {
  background: #27416E;
  border-radius: 100%;
  padding: 2px;
  width: 25px;
  height: 25px;
  color: #fff;
  font-size: 14px;
  text-align: center;
}
</style>